<script setup lang="ts">
  import { ref } from 'vue';

  const showMore = ref<boolean>(false);

  defineProps<{
    shortDescription: string;
    longDescription: string;
  }>();
</script>
<template>
  <p v-if="showMore" v-html="longDescription"></p>
  <p v-else v-html="shortDescription"></p>
  <a class="link margin-top" @click="showMore = !showMore">
    {{ showMore ? 'Lees minder' : 'Lees meer' }}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.5 9.5">
        <path fill="#8F116F" d="M.22.22a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06l-4 4A.75.75 0 0 1 .22 8.22l3.469-3.47-3.47-3.47A.75.75 0 0 1 .148.304L.22.22Z"/>
    </svg>                                      
</a>
</template>
