<script setup lang="ts">
  import type { Faq, FaqCategory } from '@/types/faq';
  import { ref } from 'vue';

  const expanded = ref<boolean>(false);

  defineProps<{
    faq: Faq;
    locale: string;
  }>();
</script>
<template>
  <div class="block block--faq" :class="expanded ? 'open' : ''">
    <div class="block__header" @click="expanded = !expanded">
      <p class="block__title">
        {{ locale === 'nl' ? faq.question.nl : faq.question.en }}
      </p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
        <path d="M.293.293a1 1 0 0 1 1.414 0L7 5.585 12.293.293a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414Z" />
      </svg>
    </div>
    <div class="block__content">
      <p>
        {{ locale === 'nl' ? faq.answer.nl : faq.answer.en }}
      </p>
    </div>
  </div>
</template>
