<script setup lang="ts">
  import '@vuepic/vue-datepicker/dist/main.css';

  import { nl } from 'date-fns/locale';
  import { reactive, ref } from 'vue';
  import { debounce } from '@/services/debounce';

  import VueDatePicker from '@vuepic/vue-datepicker';

  const date = defineModel<Date | any | null>('date', {
    set: (value) => {
      if (typeof value === 'string') {
        return new Date(value).toISOString().split('T')[0];
      }

      return value?.toISOString().split('T')[0];
    },
  });

  const timeFrom = defineModel<string | null>('timeFrom');
  const timeTo = defineModel<string | null>('timeTo');

  const pickerElement = ref<typeof VueDatePicker | null>();

  const time = reactive({
    from: false,
    to: false,
  });

  const allHoursInADay = Array(24)
    .fill(null)
    .map((_, i) => String(i + 1).padStart(2, '0') + ':00');

  const debounceInput = debounce((event: Event) => {
    date.value = (event.target as HTMLInputElement).value;
  }, 100);
</script>

<template>
  <div class="block__item" @click="pickerElement?.toggleMenu()">
    <p class="block__label">Wanneer</p>
    <VueDatePicker
      ref="pickerElement"
      v-model="date"
      :config="{ closeOnAutoApply: false }"
      :auto-apply="true"
      :close-on-select="false"
      format="d MMMM yyyy"
      locale="nl-NL"
      :format-locale="nl"
      timezone="Europe/Amsterdam"
      :time-picker-inline="true"
      required
      :min-date="new Date()"
    >
      <template #dp-input="{ value }">
        <p contenteditable class="block__title" v-bind="$attrs" @backspace="debounceInput($event)" placeholder="Selecteer een datum">
          {{ value }}
        </p>
      </template>
      <template #time-picker="">
        <div class="custom-time-picker-component">
          <div
            class="block__item"
            :class="{ open: time.from }"
            @click="
              time.from = !time.from;
              time.to = false;
            "
            v-outside="() => (time.from = false)"
          >
            <input type="text" id="timeFrom" name="time" v-model="timeFrom" readonly @change="time.from = false" placeholder="09:00" />
            <div class="block__dropdown select" @click.stop="">
              <ul>
                <li>
                  <label>
                    Geen
                    <input type="radio" name="select" :value="null" @click.stop="timeFrom = null" v-model="time.from" />
                  </label>
                </li>

                <template v-for="(hour, index) in allHoursInADay" :key="index">
                  <li>
                    <label>
                      {{ hour }}
                      <input type="radio" :name="`${index}-time-from`" :value="hour" v-model="timeFrom" @click="time.from = false" />
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div
            class="block__item"
            :class="{ open: time.to }"
            @click="
              time.to = !time.to;
              time.from = false;
            "
            v-outside="() => (time.to = false)"
          >
            <input type="text" v-model="timeTo" readonly @change="time.to = false" placeholder="17:00" />
            <div class="block__dropdown select" @click.stop="">
              <ul>
                <li>
                  <label>
                    Geen
                    <input type="radio" name="select" :value="null" @click.stop="timeTo = null" v-model="time.to" />
                  </label>
                </li>
                <template v-for="(hour, index) in allHoursInADay" :key="index">
                  <li>
                    <label>
                      {{ hour }}
                      <input type="radio" :name="`${index}-time-to`" :value="hour" v-model="timeTo" @click="time.to = false" />
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </VueDatePicker>
  </div>
</template>

<style scoped lang="scss">
  [contenteditable] {
    color: var(--Primary-500);
    font-weight: 500;
    cursor: pointer;

    &:empty:before {
      color: var(--Grey_400, #ddd);
      content: attr(placeholder);
      pointer-events: none;
      display: block;
    }

    &:focus {
      outline: 0px solid transparent;
    }
  }
</style>
