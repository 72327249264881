<script setup lang="ts" generic="T">
  import { useInput, type InputModel } from '@/services/input';

  import Dropdown from '../dropdown/Dropdown.vue';

  import type { DropdownItem } from '../dropdown';
  import { computed } from 'vue';

  const value = defineModel<InputModel<T>>('value', { required: true });

  const props = defineProps<{
    name: string;
    options: DropdownItem[];
    label?: string;
    description?: string;
    placeholder?: string;
  }>();

  const { modifiedValue, forId, getError } = useInput(value, props);

  const error = computed(() => getError(value.value, props.name));
</script>

<template>
  <label v-if="label" :for="forId">{{ label }}</label>
  <label v-if="description" :for="name" class="meta">{{ description }}</label>
  <Dropdown v-model:value="modifiedValue" :name="name" :options="options" class="input" :placeholder="placeholder" />
  <span v-if="error">{{ error }}</span>
</template>
