<script setup lang="ts">
  import { Fancybox, type OptionsType } from '@fancyapps/ui';
  import '@fancyapps/ui/dist/fancybox/fancybox.css';
  import { onMounted, onUnmounted, onUpdated, ref } from 'vue';

  const props = defineProps<{
    options?: OptionsType;
    media: string;
    show: boolean;
  }>();

  const container = ref<HTMLDivElement | null>(null);

  function bindGallery() {
    Fancybox.bind(container.value, '[data-fancybox]', { ...(props.options || {}) });
  }

  onMounted(bindGallery);

  onUpdated(() => {
    Fancybox.unbind(container.value);
    Fancybox.close();
    bindGallery();
  });

  onUnmounted(Fancybox.destroy);
</script>

<template>
  <div ref="container" class="block--gallery block">
    <slot></slot>
  </div>
</template>
