<script setup lang="ts">
  import { onMounted } from 'vue';
  import { favorites } from '.';
  import Location from '../../components/Location.vue';

  defineProps<{ route: string }>();

  onMounted(async () => await favorites.get());
</script>

<template>
  <template v-if="favorites.collection?.value?.data">
    <section class="section overflow">
      <div class="container">
        <div class="section__header">
          <h2 class="section__title">Favorieten</h2>
        </div>
        <div class="grid--four grid">
          <template v-for="location in favorites.collection?.value?.data.slice(0, 12)" :key="location.id">
            <Location :location="location"></Location>
          </template>
        </div>
      </div>
    </section>
  </template>
  <template v-else>
    <section class="section h-100">
      <div class="container medium">
        <div class="grid grid--two align-items-center">
          <div class="block">
            <h1 class="block__title">Favorieten</h1>
            <p class="block__text">Je hebt nog geen locaties aan je favorieten toegevoegd. Sla eenvoudig jouw top locaties op en heb ze altijd binnen handbereik.</p>
            <div class="block__button">
              <a :href="route" class="button"> Voeg locaties toe </a>
            </div>
          </div>
          <div class="block block--404">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 280 269">
              <g clip-path="url(#a)">
                <path
                  fill="#662056"
                  d="M107.71 220.7v-48.069c0-20.568-4.498-40.905-13.032-59.508L73.46 67.019c-7.034 7.857-9.918 18.719-7.726 29.118l8.303 39.98c1.153 5.547-5.536 9.129-9.457 5.2l-21.45-21.608-39.9 40.789 46.359 60.78v46.566h58.237V220.7h-.115Z"
                />
                <path
                  fill="#FEA4C8"
                  d="M156.722 84.351 52.01 114.394c-7.726 2.196-15.799-2.195-17.99-9.937l-15.914-55.81c-2.191-7.743 2.19-15.831 9.917-18.026L132.62.578c7.726-2.196 15.799 2.195 17.99 9.937l15.914 55.81c2.307 7.742-2.191 15.83-9.802 18.026Z"
                />
                <path fill="#FEA4C8" d="M133.703 82.294 92.951 59.667 70.37 100.5l40.752 22.627 22.582-40.833Z" />
                <path
                  fill="#fff"
                  d="m112.324 57.775.115-.347c3.575-6.586 1.268-14.79-5.305-18.488a13.61 13.61 0 0 0-18.451 5.316l-.116.346-.346-.115a13.61 13.61 0 0 0-18.451 5.315c-3.575 6.586-1.269 14.79 5.305 18.488l.346.115 23.64 13.173 13.263-23.803Z"
                />
                <path fill="#662056" d="M25.025 130.109 5.075 70.832 28.138 36.86c5.535 6.933 6.573 16.408 2.421 24.266l-5.65 10.977 20.642 48.184-20.527 9.822Z" />
                <path
                  fill="#fff"
                  d="M83.954 267.844v-72.681h-.23c-.693-7.857-4.498-15.368-11.187-20.568-5.305-4.16-11.763-6.471-18.451-6.471H27.677l13.262-13.288 11.186-3.582 1.154-.346c1.153-.347 2.306-.809 3.344-1.387 3.46-1.849 6.343-4.622 8.188-8.089 1.499-2.657 2.306-5.546 2.537-8.781L32.75 137.85l-16.375 10.053 21.104-19.99 26.408-3.12a19.487 19.487 0 0 0 17.068-16.87l-51.664 3.929-7.842 6.008V98.679l25.486-8.32c9.572-3.12 16.606-11.439 17.99-21.492L1.153 85.275v84.352l39.44 44.486c.923.925 1.845 1.618 2.883 2.427v51.42h40.478v-.116Z"
                />
                <path
                  fill="#662056"
                  d="M83.954 269c-.692 0-1.153-.462-1.153-1.156v-71.987a.885.885 0 0 1-.231-.578c-.692-7.857-4.497-14.906-10.725-19.759-5.074-3.929-11.301-6.124-17.76-6.124H27.678c-.461 0-.922-.231-1.038-.693-.23-.463-.115-.925.23-1.272l13.263-13.288c.115-.115.346-.231.461-.231l12.34-3.929c1.037-.346 2.075-.808 3.228-1.386 3.23-1.733 5.997-4.391 7.727-7.626 1.153-2.08 1.96-4.391 2.191-6.818l-32.866 4.853-16.26 9.822c-.462.347-1.154.231-1.5-.347-.346-.462-.346-1.155.115-1.502l21.104-19.99.692-.347 26.409-3.119c7.842-.925 14.184-6.818 15.799-14.444l-49.82 3.813-7.61 5.777c-.346.232-.808.347-1.269.116-.346-.231-.692-.578-.692-1.04V98.679c0-.462.346-.924.807-1.155l25.486-8.32c8.534-2.773 14.992-10.053 16.837-18.834L2.306 86.2v82.965l39.094 44.14c.692.693 1.615 1.386 2.653 2.195.346.231.461.578.461.925v51.419c0 .694-.461 1.156-1.153 1.156s-1.153-.462-1.153-1.156v-50.842a23.024 23.024 0 0 1-2.538-2.195L.346 170.32c-.23-.115-.346-.462-.346-.693V85.276c0-.578.346-1.04.923-1.156L64.58 67.828c.346-.116.807 0 1.038.23.23.232.461.694.346 1.04-1.5 10.4-8.765 19.182-18.798 22.417l-24.678 8.089v15.946l5.996-4.622c.231-.116.346-.231.577-.231l51.664-3.929c.346 0 .692.115.922.346.231.232.346.578.346.925-1.268 9.359-8.649 16.755-17.99 17.795l-26.062 3.119-13.147 12.48 7.38-4.507c.116-.115.231-.115.462-.115l34.596-5.2c.346 0 .692 0 .923.231.23.231.346.578.346.925-.116 3.235-1.038 6.47-2.653 9.244a21.274 21.274 0 0 1-8.649 8.55c-1.153.578-2.421 1.156-3.575 1.502l-12.108 3.814-11.071 11.092H54.2c6.92 0 13.723 2.427 19.143 6.702 6.458 5.2 10.61 12.48 11.532 20.684.231.231.346.462.346.808v72.681c-.115.694-.576 1.156-1.268 1.156Z"
                />
                <path fill="#662056" d="M29.512 110.025.268 135.317l1.507 1.75 29.245-25.293-1.508-1.749Z" />
                <path fill="#662056" d="M32.953 136.044.898 158.064l1.305 1.907 32.054-22.021-1.304-1.906Z" />
                <path
                  fill="#fff"
                  d="m225.453 267.151 14.992-96.022a26.388 26.388 0 0 0 19.028-.462c8.764-3.698 14.761-12.018 15.684-21.493l3.69-41.713c.576-7.049-1.73-14.097-6.458-19.297l-3.229-3.466-4.267-10.631c-2.537-6.124-9.11-9.475-15.453-7.857l-23.18 5.777c-4.036 1.04-7.957-2.31-7.611-6.47l3.69-48.763a10.04 10.04 0 0 0-10.032-10.861c-4.844 0-9.111 3.466-9.918 8.32l-8.995 49.454a148.03 148.03 0 0 0-1.73 39.056l-45.321 164.543h79.11v-.115Z"
                />
                <path
                  fill="#662056"
                  d="M145.997 268.306h-.346c-.577-.115-.923-.808-.808-1.386l45.206-164.312c-1.153-13.057-.576-26.345 1.845-39.171l8.996-49.456c.922-5.315 5.65-9.244 11.07-9.244 3.114 0 6.112 1.271 8.303 3.582 2.076 2.311 3.229 5.431 2.999 8.551l-3.69 48.762c-.116 1.618.576 3.12 1.729 4.275 1.269 1.04 2.883 1.387 4.498 1.04l23.179-5.777c6.92-1.733 14.185 1.964 16.837 8.55l4.267 10.516 2.999 3.35c4.958 5.431 7.495 12.827 6.804 20.222l-3.691 41.713c-.922 9.938-7.15 18.604-16.375 22.532-5.882 2.543-12.455 2.889-18.452.925l-14.761 94.635c-.115.578-.692 1.04-1.268.925-.577-.116-1.038-.694-.923-1.271l14.992-96.022c0-.347.23-.694.576-.809.346-.231.692-.231 1.038-.116 5.882 2.196 12.34 1.965 18.106-.462 8.418-3.582 14.184-11.439 14.991-20.568l3.691-41.713c.576-6.702-1.615-13.404-6.228-18.373l-3.229-3.466c-.115-.116-.115-.231-.23-.347l-4.267-10.63c-2.307-5.547-8.303-8.667-14.185-7.165l-23.179 5.778c-2.307.578-4.728 0-6.458-1.502a6.993 6.993 0 0 1-2.537-6.124l3.69-48.762c.231-2.543-.692-4.97-2.306-6.818-2.076-2.31-4.383-3.235-6.92-3.235-4.267 0-8.072 3.12-8.764 7.395l-8.995 49.455a146.083 146.083 0 0 0-1.73 38.825v.462L147.15 267.613c-.231.347-.692.693-1.153.693Z"
                />
                <path
                  fill="#662056"
                  d="M240.099 172.169c-.115 0-.231 0-.461-.116l-29.753-11.439c-11.648-4.507-18.797-16.293-17.529-28.657l.923-8.666-1.499-8.204c-.116-.578.345-1.271.922-1.387a1.197 1.197 0 0 1 1.384.925l1.499 8.319v.347l-.922 8.782c-1.154 11.324 5.42 22.185 16.029 26.23l29.753 11.439c.577.231.923.925.692 1.502-.231.578-.577.925-1.038.925Z"
                />
                <path
                  fill="#662056"
                  d="M216.228 129.069a6.16 6.16 0 0 1-5.882-4.391l-3.113-9.821c-.462-1.618-.346-3.351.461-4.854.807-1.502 2.191-2.542 3.806-3.004l7.034-2.195c.577-.231 1.269.115 1.499.809.231.577-.115 1.271-.807 1.502l-7.034 2.195c-1.038.347-1.846.925-2.422 1.849-.462.924-.577 1.964-.346 3.004l3.113 9.822c.577 1.849 2.307 2.889 4.152 2.658l37.941-5.085c.576-.115 1.268.347 1.268 1.04.116.578-.346 1.271-1.038 1.271l-37.94 5.085c-.116.115-.462.115-.692.115Z"
                />
                <path
                  fill="#662056"
                  d="M220.61 148.828c-2.768 0-5.19-1.733-6.112-4.391l-2.999-9.359c-.576-1.849-.346-3.813.692-5.547 1.038-1.617 2.768-2.657 4.728-2.888l2.768-.347c.692-.116 1.153.347 1.269 1.04.115.578-.346 1.155-1.038 1.271l-2.883.347c-1.269.115-2.422.809-2.999 1.848-.692 1.04-.807 2.311-.461 3.467l2.998 9.359c.577 1.734 2.076 2.774 3.921 2.774l34.251-.463c.576 0 1.153.463 1.153 1.156 0 .693-.461 1.155-1.153 1.155l-34.135.578Z"
                />
                <path
                  fill="#662056"
                  d="M212.998 108.501c-2.883 0-5.535-1.964-6.342-4.853l-2.191-7.626c-.808-3.005.576-6.24 3.344-7.627 33.097-17.101 34.02-17.101 34.481-17.101.692 0 1.153.462 1.153 1.155 0 .578-.461 1.04-1.038 1.156-1.383.462-14.299 6.817-33.558 16.87-1.845.925-2.768 3.005-2.191 4.969l2.191 7.626c.577 2.196 2.998 3.582 5.189 3.004l34.943-9.36c.576-.115 1.268.232 1.384.81.115.578-.231 1.27-.808 1.386l-34.942 9.36c-.577.231-1.038.231-1.615.231Z"
                />
                <path
                  fill="#662056"
                  d="M250.478 169.627h-.346l-29.061-8.55c-1.384-.463-2.537-1.618-2.883-3.005l-1.614-6.008c-.346-1.271-.116-2.542.692-3.698.807-1.04 1.96-1.618 3.344-1.733.692 0 1.153.462 1.153 1.155 0 .694-.461 1.156-1.153 1.156-.577 0-1.153.346-1.499.809a2.005 2.005 0 0 0-.346 1.617l1.614 6.009c.116.693.692 1.155 1.384 1.387l29.061 8.55c.577.231.923.809.807 1.387-.23.578-.692.924-1.153.924Z"
                />
                <path fill="#662056" d="M223.838 161.308c-.576 0-1.038-.463-1.153-1.04l-9.572-69.215c-.115-.577.346-1.27 1.038-1.27.577-.116 1.269.346 1.269 1.04l9.571 69.214c.116.577-.346 1.271-1.037 1.271h-.116Z" />
              </g>
              <defs>
                <clipPath id="a"><path fill="#fff" d="M0 0h280v269H0z" /></clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </section>
  </template>
</template>
