import { uuid } from "./uuid";
import { computed, watchEffect, type ModelRef } from "vue";

import type { FormData, UseForm } from "./form";

export type InputModel<T> = UseForm<FormData<T>> | number | string | null;

export function getError<T>(value: InputModel<T>, name: string): string | string[] | null {
    if (value && typeof value === 'object' && 'errors' in value && value.errors.value.has(name)) {
        return value.errors.value.get(name)?.[0] ?? null;
    }
    return null;
}


export function useInput<T>(value: ModelRef<T>, props: { name: string }) {
    const forId = uuid.value;

    function validateNamePath(name: string): void {
        if (typeof value.value === 'number' || typeof value.value === 'string' || value.value === null) {
            return;
        }

        const pathSegments = name.split('.');
        let current: any = value.value;

        if (current && typeof current === 'object' && 'form' in current) {
            current = current.form.value;
        } else {
            console.warn('Validation warning: Expected a form object or primitive, but found invalid structure.');
            return;
        }

        for (const segment of pathSegments) {
            if (!current || !Object.prototype.hasOwnProperty.call(current, segment)) {
                console.warn(`Validation warning: Property "${segment}" does not exist in the form data.`);
                return;
            }
            current = current[segment];
        }
    }

    const modifiedValue = computed({
        get(): any {
            if (typeof value.value === 'number' || typeof value.value === 'string' || value.value === null) {
                return value.value;
            }

            const pathSegments = props.name.split('.');
            let current: any = value.value;

            if (current && typeof current === 'object' && 'form' in current) {
                current = current.form.value;
            } else {
                console.warn('Invalid value structure: expected form object or primitive.');
                return undefined;
            }

            for (const segment of pathSegments) {
                if (current && Object.prototype.hasOwnProperty.call(current, segment)) {
                    current = current[segment];
                } else {
                    return undefined; // Graceful handling of undefined paths
                }
            }

            return current;
        },

        set(newValue: any): void {
            if (typeof value.value === 'number' || typeof value.value === 'string' || value.value === null) {
                value.value = newValue;
                return;
            }

            const pathSegments = props.name.split('.');
            const lastSegment = pathSegments.pop();

            if (!lastSegment) {
                throw new Error('Invalid path: cannot set a value without a property name.');
            }

            let current: any = value.value;

            if (current && typeof current === 'object' && 'form' in current) {
                current = current.form.value;
            } else {
                throw new Error('Invalid value structure: expected form object.');
            }

            for (const segment of pathSegments) {
                if (!current[segment] || typeof current[segment] !== 'object') {
                    current[segment] = {}; // Dynamically create intermediate objects
                }
                current = current[segment];
            }

            current[lastSegment] = newValue;
        },
    });


    watchEffect(() => validateNamePath(props.name));

    return {
        forId,
        modifiedValue,
        getError
    }
  

}
