<script setup lang="ts">
  import Autocomplete from '@/apps/website/elements/filter/bar/desktop/components/Autocomplete/Location.vue';
  import DateTime from '@/apps/website/elements/filter/bar/desktop/components/DateTime.vue';
  import PersonsDropdown from '@/apps/website/elements/filter/bar/desktop/components/PersonsDropdown.vue';
  import ThemeInput from '@/apps/website/elements/filter/bar/desktop/components/ThemeInput.vue';

  import type { DropDownValues, FiltersForm, QueryForm } from '../../types';
  import type { UseForm } from '@/services/form';

  defineEmits<{ (e: 'search'): void }>();

  const query = defineModel<UseForm<FiltersForm>>('query', { required: true });
  const search = defineModel<UseForm<QueryForm>>('search', { required: true });
  const dropdown = defineModel<DropDownValues>('dropdown', { required: true });
</script>

<template>
  <Autocomplete v-model:query="query.form.value.zip_code" v-model:search="search.form.value.location" placeholder="Zoek een locatie" />
  <DateTime v-model:date="query.form.value.date" v-model:time-from="search.form.value.time_from" v-model:time-to="search.form.value.time_to" placeholder="Selecteer een datum" />
  <PersonsDropdown v-model:value="query.form.value.persons" placeholder="Selecteer personen" />
  <ThemeInput v-model:theme="query.form.value.theme" v-model:search="search.form.value.theme" :dropdown="dropdown.theme" placeholder="Kies een gelegenheid" />

  <div class="block__item">
    <button class="button" @click="$emit('search')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
        <path
          fill="#FFF"
          d="M11.994 2.058a7.027 7.027 0 0 1 .503 9.378l2.783 2.784a.75.75 0 1 1-1.06 1.06l-2.784-2.783a7.027 7.027 0 1 1 .558-10.44ZM3.12 3.118a5.526 5.526 0 0 0 7.774 7.856.248.248 0 0 1 .037-.044l.042-.038.136-.141a5.526 5.526 0 0 0-7.99-7.632Z"
        />
      </svg>
    </button>
  </div>
</template>
