<script setup lang="ts">
  import { useFilter } from '..';

  import Desktop from './desktop/Desktop.vue';
  import Mobile from './mobile/Mobile.vue';

  const { filter, dropdown, query, search } = useFilter();

  const props = defineProps<{
    route: {
      search_route: string;
      current_route: string;
    };
    isMobile: boolean;
  }>();

  function handleSearch() {
    if (props.route.current_route !== props.route.search_route) {
      const queryString = window.location.search;
      window.location.href = `/locatie${queryString}`;
    }

    filter.search();
  }
</script>

<template>
  <Mobile v-if="isMobile" v-model:query="query" v-model:search="search" v-model:dropdown="dropdown" @search="handleSearch" />
  <Desktop v-if="!isMobile" v-model:query="query" v-model:search="search" v-model:dropdown="dropdown" @search="handleSearch" />
</template>
