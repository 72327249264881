<script setup lang="ts">
  import { euro } from '@/services/currency';

  import AddFavorite from '../elements/favorite/AddFavorite.vue';
  import AddCompare from '../elements/compare/AddCompare.vue';

  import type { Location } from '@/types/location';
  import { computed } from 'vue';

  const props = defineProps<{ location?: Location }>();

  const availableFromPrice = computed<number>(() => {
    if (!props.location || !props.location.spaces.length) {
      return 0;
    }
    return props.location.spaces.reduce((acc, space) => {
      if (space.price_per_person < acc) {
        return space.price_per_person;
      }
      return acc;
    }, props.location.spaces[0].price_per_person);
  });
</script>

<template>
  <template v-if="location">
    <a class="block block--location" :href="location.route">
      <div class="block__wrapper">
        <p v-if="location.show_discount_label" class="block__label">Aanbieding</p>
        <AddFavorite :id="location.id" />
        <img :src="location?.media[0]?.url" alt="" class="block__img" />
      </div>
      <div class="block__header">
        <h3 class="block__title">
          {{ location.name }}
        </h3>
        <template v-if="location.current_score">
          <div class="block__rating">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.714 13.219">
              <path
                fill="#FCCD03"
                stroke="#FCCD03"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M6.677.862a.2.2 0 0 1 .36 0l1.73 3.534a.2.2 0 0 0 .15.11l3.875.57a.2.2 0 0 1 .111.341L10.098 8.17a.2.2 0 0 0-.057.176l.662 3.887a.2.2 0 0 1-.291.21L6.951 10.61a.2.2 0 0 0-.188 0l-3.461 1.834a.2.2 0 0 1-.29-.21l.66-3.888a.2.2 0 0 0-.056-.176L.81 5.417a.2.2 0 0 1 .11-.34l3.875-.571a.2.2 0 0 0 .15-.11L6.677.862Z"
              />
            </svg>
            {{ location.current_score }}
          </div>
        </template>
      </div>
      <div class="block__content">
        <p class="block__text">
          <template v-if="!location.facilities.length"> - </template>
          <template v-else>
            <template v-if="location.spaces.length > 1"> {{ location.spaces.length }} zalen • </template>
            <template v-else> {{ location.spaces.length }} zaal • </template>
            <template v-for="(item, index) in location.facilities.slice(0, 2)" :key="index">
              {{ item.name }}
              <template v-if="index < location.facilities.length - 1"> • </template>
            </template>
          </template>
        </p>
      </div>
      <div class="block__footer">
        <AddCompare :id="location.id" />
        <div class="block__price">
          <span>vanaf {{ euro.format(availableFromPrice) }}</span> p.p.
        </div>
      </div>
    </a>
  </template>
</template>
