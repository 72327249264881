<script setup lang="ts">
  import type { Theme } from '@/types/location';
  import type { Supplier } from '@/types/suppliers';
  import { computed } from 'vue';
  import { FilterSuppliersModal, openFilterModal, type SupplierFilter } from '.';
  import { useForm } from '@/services/form';
  import type { GroupedServices, Service } from '@/types/service';

  const supplierFilters = useForm<SupplierFilter>({
    themes: [],
    services: [],
    max_radius: 10,
    latitude: undefined,
    longitude: undefined,
    zip_code: '',
    count: 0,
  });

  const props = defineProps<{
    suppliers: Supplier[];
    themes: Theme[];
    groupedServices: GroupedServices[];
    featured_services: Service[];
  }>();

  const filteredSuppliers = computed(() => filterSuppliers());

  function filterSuppliers() {
    let filtered = props.suppliers;

    // Filter out themes
    if (supplierFilters.form.value.themes.length) {
      filtered = filtered.filter((supplier) => {
        return supplier.themes.some((theme) => {
          return supplierFilters.form.value.themes.some((selectedTheme) => selectedTheme.id === theme.id);
        });
      });
    }

    // Filter out services
    if (supplierFilters.form.value.services.length) {
      filtered = filtered.filter((supplier) => {
        return supplier.services.some((service) => {
          return supplierFilters.form.value.services.some((selectedService) => selectedService.id === service.id);
        });
      });
    }

    // Filter out distance
    if (supplierFilters.form.value.max_radius && supplierFilters.form.value.latitude !== undefined && supplierFilters.form.value.longitude !== undefined) {
      filtered = filtered.filter((supplier) => {
        if (supplierFilters.form.value.latitude !== undefined && supplierFilters.form.value.longitude !== undefined) {
          return isSupplierInRange(supplierFilters.form.value.longitude, supplierFilters.form.value.latitude, supplierFilters.form.value.max_radius, supplier);
        }
        return false;
      });
    }

    supplierFilters.form.value.count = filtered.length;

    return filtered;
  }

  function isSupplierInRange(longitude: number, latitude: number, radius: number, supplier: Supplier): boolean {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (supplier.latitude - latitude) * (Math.PI / 180);
    const dLon = (supplier.longitude - longitude) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(latitude * (Math.PI / 180)) * Math.cos(supplier.latitude * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance <= radius;
  }

  function toggleTheme(theme: Theme) {
    const index = supplierFilters.form.value.themes.findIndex((t) => t.id === theme.id);

    if (index === -1) {
      supplierFilters.form.value.themes.push(theme);
    } else {
      supplierFilters.form.value.themes.splice(index, 1);
    }
  }

  function toggleService(service: Service) {
    const index = supplierFilters.form.value.services.findIndex((s) => s.id === service.id);

    if (index === -1) {
      supplierFilters.form.value.services.push(service);
    } else {
      supplierFilters.form.value.services.splice(index, 1);
    }
  }
</script>
<template>
  <section class="section">
    <div class="container">
      <div class="section__header">
        <h2 class="section__title">Leveranciers</h2>
        <div class="section__filters">
          <div class="section__filter">
            <a @click="openFilterModal()" href="#" class="button filters">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                <path
                  fill="#202020"
                  d="M2.083 0c.886 0 1.643.553 1.944 1.333h8.723a.75.75 0 1 1 0 1.5l-8.723.001A2.084 2.084 0 1 1 2.083 0Zm0 1.5a.583.583 0 1 0 0 1.167.583.583 0 0 0 0-1.167ZM6.75 4.667c.886 0 1.643.553 1.944 1.333h4.056a.75.75 0 1 1 0 1.5H8.694a2.084 2.084 0 0 1-3.888 0H.75a.75.75 0 0 1 0-1.5h4.055A2.084 2.084 0 0 1 6.75 4.667Zm0 1.5a.583.583 0 1 0 0 1.166.583.583 0 0 0 0-1.166Zm4.667 3.166a2.083 2.083 0 1 1-1.944 2.834H.75a.75.75 0 1 1 0-1.5l8.723-.001a2.084 2.084 0 0 1 1.944-1.333Zm0 1.5a.583.583 0 1 0 0 1.167.583.583 0 0 0 0-1.167Z"
                />
              </svg>
              Filters
            </a>
          </div>
        </div>
      </div>
      <div class="grid--sidebar grid">
        <div class="sidebar">
          <div class="margin block">
            <div class="block__form">
              <label>Gelegenheid</label>
              <ul>
                <template v-for="theme in themes" v-bind:key="theme.id">
                  <li>
                    <span class="block__form__checkbox">
                      <input id="" type="checkbox" name="" @click="toggleTheme(theme)" />
                      {{ theme.name }}
                    </span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="margin block">
            <div class="block__form">
              <label>Diensten</label>
              <ul>
                <template v-for="service in featured_services" v-bind:key="service.id">
                  <li>
                    <span class="block__form__checkbox">
                      <input id="" type="checkbox" name="" @click="toggleService(service)" />
                      {{ service.name }}
                    </span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="grid--three grid">
            <template v-for="supplier in filteredSuppliers" v-bind:key="supplier.id">
              <a :href="`/leverancier/${supplier.id}`" class="block--leverancier block">
                <img :src="supplier.media?.[0]?.url" :alt="supplier.name" class="block__img" />
                <div class="block__content">
                  <div class="block__header">
                    <p class="block__title">
                      {{ supplier.name }}
                    </p>
                  </div>
                  <p class="block__text">
                    {{ supplier.city }}
                  </p>
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FilterSuppliersModal v-model:values="supplierFilters" :services="groupedServices" />
</template>
