import { computed, type ModelRef, type Ref } from "vue";

export type DropdownValue = string | number | string[] | number[];

export interface DropdownItem {
  name: string;
  value: DropdownValue | DropdownValue[];
}

export interface DropdownProps<T> {
  label?: string;
  options?: T[];
  placeholder?: string;
  disabled?: boolean;
  error?: string; 
  searchable?: boolean; 
  inputClass?: string;
  excludeFromOutsideClick?: string;
}

export interface DropdownState<T> {
  selected: T;
  focused: number;
  search: string | null;
}

export function useDropdown<T, E>(
  value: ModelRef<T>, 
  state: ModelRef<DropdownState<T | T[] | null | undefined>>, 
  props: DropdownProps<E>, 
  details: Ref<HTMLDetailsElement | null>
) {

  const filtered = computed(() => (state.value.search === '' ? props.options : props.options?.filter(({ name }) => name.toLowerCase().includes(state.value.search?.toLowerCase() ?? ''))));

  const includes = (incl: DropdownValue | DropdownValue[]): boolean => (Array.isArray(value.value) ? value.value?.includes(incl) : value.value === incl);

  function selectOption(item?: DropdownItem) {
    if (!item) return;    

    if (Array.isArray(value.value)) {
      const selectedIndex = value.value.findIndex((opt: DropdownValue) => opt === item.value);

      if (selectedIndex > -1) {
        value.value?.splice(selectedIndex, 1);
        state.value.selected?.splice(selectedIndex, 1);
      } else {
        value.value?.push(item.value);
        state.value.selected?.push(item.name);
      }

      return;
    }

    if (value.value === item.value) {
      value.value = '';
      state.value.selected = undefined;
    } else {
      value.value = item.value;
      state.value.selected = item.name;
    }    

    details.value?.removeAttribute('open');
  }

  return {
    filtered,
    includes,
    selectOption,
  }
}