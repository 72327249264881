<script setup lang="ts">
  import type { Location } from '@/types/location';

  const values = defineModel<Location[]>('values', { required: true });
</script>

<template>
  <ul class="block__list">
    <li>
      <span>Faciliteiten</span>
      <template v-for="({ facilities }, index) in values" :key="index">
        <span>{{ facilities.length > 0 ? facilities.map(({ name }) => name).join(', ') : 'Geen faciliteiten' }}</span>
      </template>
    </li>
  </ul>
  <ul class="block__list">
    <li>
      <span>Ruimte</span>
      <template v-for="(item, index) in values" :key="index">
        <span>{{ item.spaces.length }}</span>
      </template>
    </li>
  </ul>
</template>
