import { computed, onBeforeMount, ref, shallowRef, watch } from 'vue';

import { api } from '@/services/api';
import { useForm } from '@/services/form';
import { storage } from '@/services/storage';
import { removeEmpty } from '@/services/clear';
import { paramsObject, updateQueryParam } from '@/services/search';
import { useAutocomplete } from './bar/desktop/components/Autocomplete';

import type { Pagination } from '@/types/pagination';
import type { Location } from '@/types/location';
import type { Create, DropDownValues, FiltersForm, QueryForm } from './types';

export const FILTERS_STORAGE_KEY = '__diezv_filters';
export const SEARCH_STORAGE_KEY = '__diezv_search';

const locations = shallowRef<Pagination<Location>>();
const create = shallowRef<Create | null>(null);

const loading = ref(false);

const currentlyStoredFilters = storage.get<FiltersForm>(FILTERS_STORAGE_KEY);
const currentlyStoredSearch = storage.get<QueryForm>(SEARCH_STORAGE_KEY);

const defaultSearchFormValues = <K extends keyof QueryForm>(field: K): QueryForm[K] | undefined => {
  if (Array.isArray(currentlyStoredSearch?.[field])) {
    return currentlyStoredSearch?.[field].join(', ') as QueryForm[K];
  }

  return currentlyStoredSearch?.[field];
};

const search = useForm<QueryForm>({
  location: defaultSearchFormValues('location'),
  range: defaultSearchFormValues('range'),
  facilities: defaultSearchFormValues('facilities'),
  date: defaultSearchFormValues('date'),
  time_from: defaultSearchFormValues('time_from'),
  time_to: defaultSearchFormValues('time_to'),
  theme: defaultSearchFormValues('theme'),
  persons: defaultSearchFormValues('persons'),
  price_min: defaultSearchFormValues('price_min'),
  price_max: defaultSearchFormValues('price_max'),
  sort: defaultSearchFormValues('sort'),
});

const query = useForm<FiltersForm>({
  zip_code: currentlyStoredFilters?.['zip_code'] ?? null,
  range: currentlyStoredFilters?.['range'],
  date: currentlyStoredFilters?.['date'],
  facilities: currentlyStoredFilters?.['facilities'] ?? null,
  time_from: currentlyStoredFilters?.['time_from'],
  time_to: currentlyStoredFilters?.['time_to'],
  theme: currentlyStoredFilters?.['theme'],
  persons: currentlyStoredFilters?.['persons'] ?? 0,
  price_min: currentlyStoredFilters?.['price_min'],
  price_max: currentlyStoredFilters?.['price_max'],
  sort: currentlyStoredFilters?.['sort'],
});

function applyFilters() {
  const queryUpdates: Record<string, FiltersForm[keyof FiltersForm]> = {
    date: query.form.value.date,
    persons: query.form.value.persons,
    time_from: search.form.value.time_from,
    time_to: search.form.value.time_to,
    theme: query.form.value.theme,
    sort: search.form.value.sort,
  };

  Object.keys(queryUpdates).forEach((key) => {

    if (queryUpdates[key] !== undefined) {
      // @ts-expect-error
      query.form.value[key] = queryUpdates[key];
    }
  });

  const searchUpdates: Record<string, FiltersForm[keyof FiltersForm]> = {
    location: search.form.value.location,
    persons: query.form.value.persons,
    theme: create.value?.themes.find(({ id }) => id === query.form.value.theme)?.name ?? '',
    range: query.form.value.range,
    time_from: search.form.value.time_from,
    time_to: search.form.value.time_to,
    facilities: query.form.value.facilities ? query.form.value.facilities.join(', ') : '',
    price_min: query.form.value.price_min,
    price_max: query.form.value.price_max,
    sort: search.form.value.sort,
  };

  Object.keys(searchUpdates).forEach((key) => {
    // @ts-expect-error
    updateQueryParam(key, search.form.value[key]);

    if (searchUpdates[key] !== undefined) {
      // @ts-expect-error
      search.form.value[key] = searchUpdates[key];
    }
  });
}

const dropdown: DropDownValues = {
  theme: computed(() => create.value?.themes?.map(({ id, name }) => ({ name, value: id }))),
  arrangements: computed(() => create.value?.arrangements?.map(({ id, name }) => ({ name, value: id }))),
  sort: [
    { value: 'price-acs', name: 'Prijs laag - hoog' },
    { value: 'price-desc', name: 'Prijs hoog - laag' },
  ],
};

const filter = {
  async search() {
    applyFilters();

    loading.value = true;

    try {
      // @ts-expect-error
      const { data } = await api.post<Pagination<Location>>(`api/location`, removeEmpty(query.form.value), removeEmpty(query.form.value));

      locations.value = data;
      return true;
    } catch (error) {
      return false;
    } finally {
      loading.value = false;
    }
  },
  async static() {
    if (create.value?.arrangements) return;

    try {
      const { data } = await api.get<Create>('api/static');

      create.value = data;
    } catch (error) {
      console.error(error);
    }
  },
};

if (!create.value) filter.static();

export function useFilter() {
  watch(
    query.form,
    (newForm) => {
      storage.set(FILTERS_STORAGE_KEY, { ...newForm });
    },
    { deep: true },
  );

  watch(
    search.form,
    (newSearch) => {
      storage.set(SEARCH_STORAGE_KEY, { ...newSearch });
    },
    { deep: true },
  );

  watch(
    paramsObject,
    () => {
      Object.assign(search.form.value, paramsObject.value);
    },
    { deep: true },
  );

  watch(
    [search.form, paramsObject],
    ([newSearch, newParams]) => {
      const combinedSearch = { ...newSearch, ...newParams };
      storage.set(SEARCH_STORAGE_KEY, combinedSearch);
      applyFilters();
    },
    { deep: true },
  );

  onBeforeMount(async () => {
    applyFilters();

    if (search.form.value.location && !query.form.value.zip_code) {
      const { init, fetchSuggestions, getPostalCodeByPlaceId, pattern, suggestions } = useAutocomplete(null, ref(search.form.value.location), ref(query.form.value.zip_code));

      await init();
      await fetchSuggestions(pattern, search.form.value.location);

      try {
        const postalCode = await getPostalCodeByPlaceId(suggestions.value[0].place_id);
        query.form.value.zip_code = postalCode;
      } catch (error) {
        console.error('Error fetching postal code:', error);
      }
    }
  });

  return {
    filter,
    dropdown,
    locations,
    create,
    loading,
    query,
    search,
  };
}
