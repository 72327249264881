<script setup lang="ts">
  import { ref } from 'vue';
  import Dropdown from '@/components/dropdown/Dropdown.vue';

  import type { DropDownValues } from '../../../types';

  const theme = defineModel<number>('theme');
  const search = defineModel<string | number>('search');

  defineProps<{ dropdown: DropDownValues['theme'] }>();

  const open = ref<boolean>(false);
</script>

<template>
  <div class="block__item" @click="open = !open">
    <p class="block__label">Gelegenheid</p>
    <Dropdown v-model:value="theme" v-model:selected="search" v-model:open="open" :options="dropdown.value" placeholder="Kies een gelegenheid" input-class="block__title" :searchable="false" :exclude-from-outside-click="`.block__item`" />
  </div>
</template>
