<script setup lang="ts">
  import type { FaqCategory } from '@/types/faq';
  import FaqRow from './FaqRow.vue';
  import { useI18n } from 'vue-i18n';

  const { locale } = useI18n();

  defineProps<{
    categories: FaqCategory[];
  }>();
</script>
<template>
  <div class="content">
    <template v-for="category in categories" :key="category.id">
      <div class="block margin margin-title">
        <h2 class="block__title alt big">{{ locale === 'nl' ? category.title.nl : category.title.en }}</h2>
      </div>
      <template v-for="faq in category.faq_questions" :key="faq.id">
        <FaqRow :faq="faq" :locale />
      </template>
    </template>
  </div>
</template>
