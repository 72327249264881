<script setup lang="ts">
  import { onMounted, shallowRef } from 'vue';
  import { compare } from '.';

  import type { Location } from '@/types/location';
  import Accordion, { type AccordionObject } from '@/components/Accordion.vue';

  import General from './components/General.vue';
  import Facilities from './components/Facilities.vue';
  import Suggestion from '../../components/Location.vue';
  import Comparison from '../../components/Location.vue';

  defineProps<{
    suggestions: Location[];
    route: string;
  }>();

  const accordions = shallowRef<AccordionObject[]>([
    {
      title: 'Algemeen',
      component: General,
    },
    {
      title: 'Faciliteiten',
      component: Facilities,
    },
  ]);

  onMounted(async () => await compare.get());
</script>

<template>
  <template v-if="compare.collection.value && compare.collection.value?.data.length > 0">
    <section class="section">
      <div class="container">
        <h2 class="section__title">Vergelijken</h2>
        <div class="grid grid--compare">
          <template v-for="item in compare.collection.value.data" :key="item.id">
            <Comparison :location="item" />
          </template>
        </div>
        <Accordion :accordion="accordions" :values="compare.collection.value.data" />
      </div>
    </section>
  </template>
  <template v-else>
    <section class="section h-100">
      <div class="container medium">
        <div class="grid grid--two align-items-center">
          <div class="block">
            <h1 class="block__title">Vergelijken</h1>
            <p class="block__text">Er zijn geen locaties toegevoegd om te vergelijken. Voeg locaties toe, zodat je deze met elkaar kunt vergelijken.</p>
            <div class="block__button">
              <a :href="route" class="button"> Voeg locaties toe </a>
            </div>
          </div>
          <div class="block block--404">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 380 269">
              <path
                fill="#662056"
                d="M117.366 78.283c-2.944 0-5.247-2.298-5.247-5.233 0-2.936 2.303-5.234 5.247-5.234s5.248 2.298 5.248 5.234c0 2.935-2.304 5.233-5.248 5.233Zm0-7.914c-1.536 0-2.688 1.149-2.688 2.68 0 1.532 1.152 2.681 2.688 2.681 1.536 0 2.688-1.149 2.688-2.68 0-1.404-1.28-2.68-2.688-2.68Z"
              />
              <path
                fill="#662056"
                d="M129.013 78.283c-2.944 0-5.247-2.298-5.247-5.233 0-2.936 2.303-5.234 5.247-5.234s5.248 2.298 5.248 5.234c0 2.935-2.304 5.233-5.248 5.233Zm0-7.914c-1.536 0-2.688 1.149-2.688 2.68 0 1.532 1.152 2.681 2.688 2.681 1.536 0 2.688-1.149 2.688-2.68 0-1.404-1.152-2.68-2.688-2.68Z"
              />
              <path
                fill="#662056"
                d="M140.788 78.283c-2.944 0-5.247-2.298-5.247-5.233 0-2.936 2.303-5.234 5.247-5.234s5.248 2.298 5.248 5.234c0 2.935-2.432 5.233-5.248 5.233Zm0-7.914c-1.536 0-2.688 1.149-2.688 2.68 0 1.532 1.152 2.681 2.688 2.681 1.536 0 2.688-1.149 2.688-2.68 0-1.404-1.28-2.68-2.688-2.68Z"
              />
              <rect width="151.949" height="176" fill="#FEB6D3" rx="10" />
              <rect width="24.318" height="24.252" x="25.598" y="24.78" fill="#662056" rx="2" />
              <rect width="24.318" height="24.252" x="25.598" y="75.837" fill="#662056" rx="2" />
              <rect width="24.318" height="24.252" x="25.598" y="126.893" fill="#662056" rx="2" />
              <path fill="#662056" d="M115.958 33.46H58.363c-.64 0-1.152-.51-1.152-1.15 0-.637.512-1.148 1.152-1.148h57.595c.64 0 1.152.51 1.152 1.149 0 .638-.512 1.149-1.152 1.149Z" />
              <path fill="#662056" d="M115.958 40.352H58.363c-.64 0-1.152-.51-1.152-1.148 0-.639.512-1.15 1.152-1.15h57.595c.64 0 1.152.511 1.152 1.15 0 .638-.512 1.148-1.152 1.148Z" />
              <path
                fill="#662056"
                d="M123.254 58.987H28.541c-7.55 0-13.694-6.126-13.694-13.657V28.226c0-7.53 6.143-13.658 13.694-13.658h94.713c7.551 0 13.694 6.127 13.694 13.658V45.33c0 7.53-6.143 13.657-13.694 13.657ZM28.541 16.866c-6.27 0-11.518 5.105-11.518 11.488v17.104c0 6.254 5.12 11.487 11.518 11.487h94.713c6.271 0 11.519-5.105 11.519-11.487V28.226c0-6.254-5.12-11.488-11.519-11.488H28.541v.128Z"
              />
              <path fill="#662056" d="M115.958 84.39H58.363c-.64 0-1.152-.511-1.152-1.15 0-.638.512-1.148 1.152-1.148h57.595c.64 0 1.152.51 1.152 1.149 0 .638-.512 1.148-1.152 1.148Z" />
              <path fill="#662056" d="M115.958 91.282H58.363c-.64 0-1.152-.51-1.152-1.149 0-.638.512-1.149 1.152-1.149h57.595c.64 0 1.152.51 1.152 1.15 0 .637-.512 1.148-1.152 1.148Z" />
              <path
                fill="#662056"
                d="M123.254 109.917H28.541c-7.55 0-13.694-6.127-13.694-13.657V79.156c0-7.531 6.143-13.658 13.694-13.658h94.713c7.551 0 13.694 6.127 13.694 13.658V96.26c0 7.53-6.143 13.657-13.694 13.657ZM28.541 67.796c-6.27 0-11.518 5.105-11.518 11.487V96.26c0 6.254 5.12 11.487 11.518 11.487h94.713c6.271 0 11.519-5.105 11.519-11.487V79.156c0-6.255-5.12-11.488-11.519-11.488H28.541v.128Z"
              />
              <path fill="#662056" d="M115.958 135.318H58.363c-.64 0-1.152-.511-1.152-1.149s.512-1.148 1.152-1.148h57.595c.64 0 1.152.51 1.152 1.148 0 .638-.512 1.149-1.152 1.149Z" />
              <path fill="#662056" d="M115.958 142.211H58.363c-.64 0-1.152-.511-1.152-1.149s.512-1.149 1.152-1.149h57.595c.64 0 1.152.511 1.152 1.149s-.512 1.149-1.152 1.149Z" />
              <path
                fill="#662056"
                d="M123.254 160.846H28.541c-7.55 0-13.694-6.127-13.694-13.658v-17.104c0-7.53 6.143-13.657 13.694-13.657h94.713c7.551 0 13.694 6.127 13.694 13.657v17.104c0 7.531-6.143 13.658-13.694 13.658Zm-94.713-42.122c-6.27 0-11.518 5.106-11.518 11.488v17.104c0 6.254 5.12 11.488 11.518 11.488h94.713c6.271 0 11.519-5.106 11.519-11.488v-17.104c0-6.254-5.12-11.488-11.519-11.488H28.541Z"
              />
              <path fill="#662056" d="M380 267.257v-38.409c0-16.958-9.883-32.322-25.143-39.569l-74.266-34.64c-15.406-7.247-33.718-1.305-42.147 13.334l-55.664 99.284H380Z" />
              <path
                fill="#fff"
                d="M239.17 267.258v-85.225l1.454-28.118c.726-12.755-3.634-25.22-12.063-34.786l-24.126-27.393c-2.18-2.464-5.522-3.334-8.574-2.32-4.651 1.595-6.686 7.103-4.215 11.451l12.644 22.031-34.154 9.276-39.822-15.509c-3.633-1.449-7.848 0-9.882 3.334-1.308 2.029-.582 4.783 1.598 5.942l71.36 38.989-27.178 102.328h72.958Z"
              />
              <path
                fill="#662056"
                d="M166.357 268.706h-.436c-.726-.145-1.308-1.014-1.017-1.739l26.887-101.313-70.487-38.409c-1.454-.725-2.326-2.029-2.762-3.479-.436-1.449-.145-3.043.582-4.493 2.47-4.058 7.266-5.652 11.626-3.913l39.386 15.508 31.829-8.696-11.773-20.437c-1.453-2.463-1.598-5.362-.581-7.971 1.017-2.61 3.052-4.638 5.668-5.508 3.634-1.304 7.558-.145 10.174 2.609l24.125 27.394c8.72 9.855 13.08 22.61 12.499 35.8l-1.453 28.118v85.225c0 .869-.582 1.449-1.454 1.449s-1.453-.58-1.453-1.449v-85.225l1.453-28.263c.727-12.465-3.488-24.35-11.772-33.626l-23.98-27.539a6.385 6.385 0 0 0-6.976-1.884c-1.89.58-3.198 2.03-3.924 3.913-.727 1.74-.582 3.769.436 5.508l12.644 22.031c.291.435.291.87.145 1.304-.145.435-.581.725-1.017.87l-34.154 9.276c-.29.145-.581 0-.872 0l-39.822-15.653c-3.052-1.16-6.394 0-8.138 2.753-.436.725-.582 1.45-.291 2.175.145.724.727 1.304 1.453 1.739l71.36 38.989c.581.29.872 1.014.726 1.594l-27.177 102.328c-.145.579-.727 1.014-1.454 1.014Z"
              />
              <path fill="#fff" d="m151.969 133.333-15.405-26.814c-1.454-2.464.581-5.507 3.488-5.218 3.633.435 6.976 2.32 9.156 5.218l18.893 24.06-16.132 2.754Z" />
              <path
                fill="#662056"
                d="M151.97 134.782c-.436 0-1.018-.29-1.308-.725l-15.406-26.814c-.872-1.594-.872-3.623.145-5.218 1.018-1.594 2.762-2.464 4.651-2.174 4.069.435 7.703 2.609 10.174 5.798l18.748 23.915c.436.58.436 1.594-.291 2.029-.581.435-1.599.435-2.035-.29l-18.893-24.06c-1.89-2.609-4.942-4.348-8.139-4.638-.727-.145-1.453.29-1.889.87-.436.724-.436 1.449 0 2.174l15.405 26.814c.436.724.146 1.594-.581 2.029 0 .145-.291.29-.581.29Z"
              />
              <path fill="#fff" d="m205.743 148.405-23.399-12.465-12.789-26.234c-1.744-3.624-5.232-5.943-9.302-6.378-2.616-.145-4.505 2.319-3.778 4.783l11.045 34.206 38.223 6.088Z" />
              <path
                fill="#662056"
                d="M205.743 149.854c-.291 0-.436 0-.727-.144l-23.399-12.465c-.29-.145-.436-.435-.581-.58l-12.789-26.234c-1.454-3.044-4.651-5.218-8.139-5.508-.727 0-1.453.29-1.889.87-.437.58-.582 1.304-.437 2.029l11.046 34.206c.291.724-.145 1.594-.872 1.884-.727.29-1.599-.145-1.889-.87l-11.046-34.206c-.581-1.594-.145-3.333.872-4.638 1.017-1.304 2.762-2.029 4.36-1.884 4.506.435 8.43 3.044 10.464 7.102l12.644 25.8 22.963 12.174c.727.435 1.018 1.305.582 2.03-.146.29-.582.434-1.163.434Z"
              />
              <path
                fill="#662056"
                d="m308.931 136.377 14.679 4.493c17.004 5.218 35.026-4.493 40.258-21.306l1.744-5.798c6.54-21.16-5.523-43.772-26.742-50.149l-3.342 5.363c-13.226-4.058-27.469 3.478-31.538 16.813l-7.994 26.234c-3.342 10.146 2.471 21.161 12.935 24.35Z"
              />
              <path fill="#662056" d="M362.701 106.089c.302-18.73-15.655-34.171-35.639-34.49-19.985-.32-36.429 14.604-36.73 33.333-.301 18.73 15.655 34.172 35.639 34.491 19.985.32 36.429-14.604 36.73-33.334Z" />
              <path fill="#662056" d="M292.944 162.756c10.755-2.319 20.057-9.856 24.126-21.016l3.633-10.146-27.177-9.711-.582 40.873Z" />
              <path fill="#662056" d="M335.527 110.577c2.471-11.305-2.035-23.48-11.627-29.133-5.959-3.478-12.644-3.913-18.603-1.594 3.198.29 11.918 1.884 13.662 7.682 1.889 7.247 1.889 20.726 16.568 23.045Z" />
              <path fill="#662056" d="M309.773 96.08c8.112-3.674 11.702-13.211 8.018-21.301-3.685-8.09-13.247-11.67-21.359-7.996-8.113 3.674-11.702 13.21-8.018 21.301 3.684 8.09 13.247 11.67 21.359 7.996Z" />
              <path fill="#662056" d="M343.521 70.139c16.132 7.247 24.852 25.074 20.201 42.467-4.505 1.305-9.592 1.449-14.533-.145-12.79-3.913-20.202-16.958-16.568-29.133 1.744-5.942 5.813-10.436 10.9-13.19Z" />
              <path fill="#662056" d="M334.83 94.704c8.864-.902 15.316-8.799 14.411-17.638-.904-8.84-8.822-15.274-17.685-14.372-8.864.902-15.316 8.799-14.411 17.638.904 8.839 8.822 15.273 17.685 14.372Z" />
              <path fill="#662056" d="M299.484 69.27c-2.616 8.696 2.326 17.972 11.046 20.726 8.72 2.609 18.021-2.32 20.783-11.016 2.761-8.696-2.326-17.972-11.046-20.726-8.865-2.754-18.021 2.174-20.783 11.015Z" />
              <path
                fill="#662056"
                d="M356.891 87.822a1.11 1.11 0 0 1-.726-.29c-.727-.435-.872-1.305-.436-2.03 3.342-5.072 10.028-4.493 10.318-4.493.727.145 1.454.725 1.308 1.595-.145.724-.726 1.45-1.598 1.304 0 0-5.232-.435-7.558 3.189-.436.435-.872.725-1.308.725Z"
              />
              <path
                fill="#662056"
                d="M356.31 87.241c-.581 0-1.162-.434-1.453-1.014-.145-.725.291-1.595 1.017-1.74 4.215-1.159 5.232-6.232 5.232-6.232a1.508 1.508 0 0 1 1.744-1.16c.727.145 1.308.87 1.163 1.74 0 .29-1.453 6.812-7.267 8.551-.145-.145-.29-.145-.436-.145Z"
              />
              <path
                fill="#fff"
                d="m288.439 166.959 4.505.435.291-17.828a20.421 20.421 0 0 1-6.25-2.319c-12.208-6.522-16.859-21.741-10.318-33.916l4.505-8.551c-2.325-1.595-3.052-4.494-1.744-6.957 1.308-2.464 4.36-3.48 6.831-2.464l1.308-2.465c6.54-12.174 21.655-16.813 34.008-10.29 10.319 5.508 15.26 17.103 12.644 27.973 4.506 1.45 7.558 5.943 6.976 10.726-.436 4.203-3.488 7.537-7.412 8.551l-.726 49.715 9.156 7.826c-3.198 8.987-11.627 17.393-23.109 22.031-18.166 7.392-37.496 2.464-42.873-10.87-4.506-10.581.872-23.191 12.208-31.597Z"
              />
              <path
                fill="#662056"
                d="M301.81 214.354c-12.499 0-23.254-5.508-27.178-15.218-4.36-10.726.727-24.205 12.935-33.337.29-.29.726-.29 1.017-.29l2.907.29.29-15.073c-1.889-.58-3.778-1.305-5.377-2.174-12.935-6.813-17.876-22.901-10.9-35.801l3.924-7.537c-2.035-2.029-2.616-5.362-1.308-8.116 1.453-2.754 4.505-4.203 7.412-3.624l.727-1.449c6.83-12.9 22.963-17.828 35.897-10.87 10.319 5.507 15.842 16.957 13.662 28.408 4.505 2.029 7.412 6.812 6.831 11.885-.436 4.348-3.343 7.971-7.412 9.566l-.727 47.975 8.575 7.392c.436.435.581 1.014.436 1.594-3.488 9.711-12.499 18.263-23.835 22.901-6.104 2.319-12.208 3.478-17.876 3.478Zm-12.935-45.946c-10.755 8.407-15.406 20.147-11.482 29.568 5.232 12.61 23.545 17.248 40.985 10.146 10.319-4.203 18.457-11.74 21.945-20.292l-8.284-7.102c-.29-.29-.581-.725-.436-1.159l.727-49.715c0-.579.436-1.159 1.017-1.449 3.488-.87 5.959-3.769 6.395-7.392.436-4.058-2.035-7.972-5.959-9.276-.726-.29-1.162-1.015-1.017-1.739 2.471-10.436-2.325-21.307-11.918-26.38-11.481-6.087-25.869-1.739-31.973 9.711l-1.308 2.464c-.291.725-1.163.87-1.89.725-1.889-.725-4.069 0-5.086 1.884-.872 1.74-.436 4.059 1.308 5.218.581.435.872 1.16.436 1.884l-4.506 8.552c-2.906 5.508-3.633 11.885-1.744 17.972 1.89 6.088 5.959 11.016 11.482 13.915 1.744 1.014 3.778 1.739 5.813 2.174.727.145 1.163.724 1.163 1.449l-.291 17.828c0 .435-.145.724-.436 1.014-.291.29-.727.435-1.163.29l-3.778-.29Z"
              />
              <path
                fill="#662056"
                d="M329.132 124.202h-.29c-.727-.145-1.308-1.015-1.018-1.74 1.163-5.073 6.105-5.797 6.25-5.797.727-.145 1.599.435 1.599 1.304.145.725-.436 1.45-1.163 1.595-.145 0-3.052.579-3.924 3.623-.145.58-.727 1.015-1.454 1.015Z"
              />
              <path
                fill="#662056"
                d="M289.02 113.04a6.608 6.608 0 0 1-4.651-1.884c-.581-.58-.436-1.449.145-2.029.582-.58 1.454-.435 2.035.145.291.29 2.471 2.464 6.395-.58.581-.435 1.599-.435 2.035.29.436.58.436 1.594-.291 2.029-2.18 1.45-4.069 2.029-5.668 2.029Z"
              />
              <path fill="#662056" d="M292.072 97.822c-.436 1.305 0 2.754 1.163 3.044 1.162.435 2.325-.435 2.761-1.74.436-1.304 0-2.753-1.163-3.043-1.017-.29-2.325.435-2.761 1.74Z" />
              <path fill="#662056" d="M297.304 90.431c-.581.58.291 2.464 1.89 4.204 1.744 1.739 3.633 2.609 4.214 2.029.582-.58-.29-2.464-1.889-4.203-1.599-1.595-3.488-2.61-4.215-2.03Z" />
              <path fill="#662056" d="M328.842 86.662c10.319 3.479 16.859 14.059 14.969 25.075-2.761 1.159-5.813 1.449-9.01.724-8.139-1.739-13.517-9.276-11.918-17.102.872-3.624 3.052-6.668 5.959-8.697Z" />
              <path
                fill="#662056"
                d="M298.176 151.305c-1.744 0-3.488-.145-5.232-.579a1.504 1.504 0 0 1-1.162-1.74c.145-.724.872-1.304 1.744-1.159 10.173 2.029 20.783-2.899 25.579-12.03.436-.725 1.308-1.015 2.034-.58.727.435 1.018 1.304.582 2.029-4.651 8.697-13.807 14.059-23.545 14.059Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  </template>
  <section class="section pink-100 overflow" v-if="compare.collection.value && compare.collection.value?.data.length > 0">
    <div class="container">
      <div class="section__header">
        <h2 class="section__title">Heb je deze locaties al gezien?</h2>
      </div>
      <div class="slider grid--four grid">
        <template v-for="(location, index) in suggestions?.slice(0, 4)" :key="index">
          <Suggestion :location="location" />
        </template>
      </div>
    </div>
  </section>
</template>
